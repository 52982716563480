import { backendHost } from '../common/configuration/ConfigProvider';
import { Box } from '@mui/material';

export const ApiDocs = () => {
    return (
        <Box
            component={'iframe'}
            src={`${backendHost}/api/3/docs`}
            sx={{
                width: '100%',
                height: '100%',
                border: 'none',
            }}
            data-testid={'t-api-docs'}
        />
    );
};
