import { CrmUser } from '../../../domain/Users/CrmUser';
import { DraftUser } from '../../../domain/Users/DraftUser';

const useCompanyCrmUsersMapper = () => {
    const fromAPI = (body: any): CrmUser[] => {
        const items = body.items;
        return items.map(_map_crm_user);
    };

    const _map_crm_user = (user: any): CrmUser => ({
        id: user.user_id,
        crmUserId: user.crm_user_id,
        isIntegrated: user.is_integrated,
        firstName: user.first_name,
        lastName: user.last_name ? user.last_name : '',
        email: user.email,
    });

    const toAPI = (body: any) => {
        return body.map(_map_crm_user_to_request_body);
    };

    const _map_crm_user_to_request_body = (draftUser: DraftUser) => ({
        crm_user_id: draftUser.crmUserId,
        first_name: draftUser.firstName,
        last_name: draftUser.lastName,
        username: draftUser.username,
        email: draftUser.email,
        password: draftUser.password,
    });

    return { fromAPI, toAPI };
};

export { useCompanyCrmUsersMapper };
