import { useState } from 'react';

export const useColumnsVisibilityEditorController = (
    columns: any[],
    handleVisibilityChange: (columnName: string, visible: boolean) => void
) => {
    const filterOutActionColumns = (columns: any[]) => {
        return columns.filter((column) => column.label != '');
    };

    const filterOutNonHideableColumns = (columns: any[]) => {
        return columns.filter((column) => column.name != 'name').filter((column) => column.name != 'label');
    };

    const [filteredColumns, setFilteredColumns] = useState<any[]>(filterOutActionColumns(columns));

    const filterColumns = (search: string) => {
        const filtered = filterOutActionColumns(columns).filter((column) =>
            column.label.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredColumns(filtered);
    };

    const hideAll = (hide: boolean) => {
        const nonActionColumns = filterOutActionColumns(columns);
        const hideableColumns = filterOutNonHideableColumns(nonActionColumns);
        hideableColumns.forEach((column) => hideColumn(column.name, hide));
    };

    const hideColumn = (columnName: string, hide: boolean) => {
        handleVisibilityChange(columnName, !hide);
    };

    return {
        filteredColumns,
        filterColumns,
        hideAll,
        hideColumn,
    };
};
