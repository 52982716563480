import { ImportReportApiConnector } from './api/ImportReportApiConnector';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedCompanyId } from '../../store/slices/companiesSlice';
import { useEffect, useState } from 'react';
import { ImportReportError } from '../../domain/Crm/ImportReportError';
import { GridFilterModel } from '@mui/x-data-grid';

export type ImportReportRow = {
    id: string;
    externalRecordId: string;
    externalRecordUrl: string;
    objectType: string;
    fieldName: string;
    errorName: string;
    severity: string;
    description: string;
    recommendations: string;
};

const useCompanyImportReportMainContentController = () => {
    const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
    const [importReport, setImportReport] = useState<ImportReportError[]>([]);
    const [importReportRows, setImportReportRows] = useState<ImportReportRow[]>([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });

    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

    const { getImportReport, downloadImportReport } = ImportReportApiConnector();

    const fetchImportReport = async (
        selectedCompanyId: string,
        page: number,
        pageSize: number,
        filterModel: GridFilterModel
    ) => {
        const objectTypeFilter = filterModel.items.filter((item) => item.field === 'objectType')[0];
        const severityFilter = filterModel.items.filter((item) => item.field === 'severity')[0];
        const objectTypeValue = objectTypeFilter?.value || '';
        const severityValue = severityFilter?.value || '';
        const results = await getImportReport(
            selectedCompanyId,
            page,
            pageSize,
            objectTypeValue,
            severityValue.toUpperCase()
        );
        results != null ? setImportReport(results) : setImportReport([]);
    };

    useEffect(() => {
        if (!selectedCompanyId) {
            return;
        }
        const { page, pageSize } = paginationModel;
        fetchImportReport(selectedCompanyId, page, pageSize, filterModel);
    }, [selectedCompanyId, paginationModel, filterModel]);

    useEffect(() => {
        if (!importReport) {
            return;
        }
        let updatedImportReportRows: ImportReportRow[] = [];
        importReport.forEach((importReportError) => {
            importReportError.errors.forEach((error) => {
                updatedImportReportRows.push({
                    id: error.id,
                    externalRecordId: importReportError.externalRecordId,
                    externalRecordUrl: importReportError.externalRecordUrl,
                    objectType: importReportError.badgerObject.objectType,
                    fieldName: error.fieldName,
                    errorName: error.errorName,
                    severity: error.severity,
                    description: error.description,
                    recommendations: error.recommendations.join(', '),
                });
            });
        });
        setImportReportRows(updatedImportReportRows);
    }, [importReport]);

    const handleDownloadImportReportButtonClick = async () => {
        if (!selectedCompanyId) {
            return;
        }
        const blob = await downloadImportReport(selectedCompanyId);
        if (!blob) {
            return;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'import_report.csv';
        a.click();
        a.remove();
    };

    return {
        handleDownloadImportReportButtonClick,
        importReport,
        importReportRows,
        setFilterModel,
        setPaginationModel,
    };
};

export { useCompanyImportReportMainContentController };
