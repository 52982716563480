export const contactsHS = [
    {
        name: 'external_record_url',
        label: 'Record URL (View Only)',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'firstname',
        label: 'First Name',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'lastname',
        label: 'Last Name',
        isMandatory: true,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        sorting_order: 1,
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'phone',
        label: 'Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        sorting_order: 0,
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'mobilephone',
        label: 'Mobile Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'jobtitle',
        label: 'Job Title',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        sorting_order: 0,
        type: 'text',
        isTextArea: false,
    },
];
