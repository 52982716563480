import { ObjectType } from '../../../../domain/Crm/ObjectType';
import CompanyDefinition from '../../../../domain/CompanyDefinition/CompanyDefinition';
import { ModalSelectorObject } from '../../../../common/components/ModalSelector';

export const mapObjectTypesToModalSelectorObjects = (
    objectTypes: ObjectType[],
    companyDefinition?: CompanyDefinition
) =>
    objectTypes.map((objectType, index) => {
        const isExcluded = companyDefinition?.badgerObjectDefinitions.some(
            (objectDefinition) => objectDefinition.namePlural === objectType.name
        );

        return {
            id: index.toString(),
            primaryText: objectType.name,
            secondaryText: objectType.label,
            isExcluded: Boolean(isExcluded),
        } as ModalSelectorObject;
    });
