import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useCompanyImportReportMainContentController } from './CompanyImportReportMainContentController';
import { Alignments, Button, ButtonTypes, Icon, loadFontAttributes, SyncDataIcons } from 'design-system';
import Typography from '@mui/material/Typography';
import { getGridStringOperators, DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';

const StyledTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
    ${({ theme }) => loadFontAttributes(theme.typography.Large.bold)};
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
`;

const StyledBox = styled(Box)`
    display: flex;
    flex-flow: column;
    background-color: white;
    width: 100%;
    height: 100%;
`;

const StyledBoxContainer = styled(Box)`
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    flex: 1;
`;

const BackgroundStyleBox = styled(Box)`
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    width: 100%;
    height: 100%;
`;

const DownloadReportContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: auto;
`;

const StyledLink = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.Text.onButton.tertiary.enabled};
`;

const StyledDataGrid = styled(DataGrid)`
    padding: 0 30px;
    padding-bottom: 30px;
    border: none !important;
    height: calc(100% - 60px) !important;

    & .MuiDataGrid-columnHeaders {
        background-color: ${({ theme }) => theme.palette.Background.table.header.default};
    }

    & .MuiDataGrid-columnHeaderTitle {
        ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)}
    }

    & .MuiDataGrid-virtualScroller {
        background-color: white;
        height: fit-content;
    }

    & .MuiDataGrid-row:hover {
        background-color: ${({ theme }) => theme.palette.Background.table.content.hover} !important;
        cursor: pointer;
    }

    & .MuiDataGrid-cell {
        outline: none !important;
    }
`;

const columns: GridColDef[] = [
    {
        field: 'externalRecordId',
        flex: 1,
        headerName: 'External record ID',
        headerAlign: 'left',
        align: 'left',
        renderCell: (params: GridCellParams) => (
            <StyledLink href={params.row.externalRecordUrl} target="_blank" rel="noreferrer">
                {params.value}
            </StyledLink>
        ),
        filterable: false,
    },
    {
        field: 'objectType',
        flex: 0.75,
        headerName: 'Object Type',
        headerAlign: 'left',
        align: 'left',
        filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals'),
    },
    {
        field: 'fieldName',
        flex: 0.75,
        headerName: 'Field Name',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
    },
    {
        field: 'errorName',
        flex: 0.75,
        headerName: 'Error Name',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
    },
    {
        field: 'severity',
        flex: 0.5,
        headerName: 'Severity',
        headerAlign: 'left',
        align: 'left',
        filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals'),
    },
    {
        field: 'description',
        flex: 2,
        headerName: 'Description',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
    },
    {
        field: 'recommendations',
        flex: 2,
        headerName: 'Recommendations',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
    },
];

const CompanyImportReportMainContent = () => {
    const { handleDownloadImportReportButtonClick, importReportRows, setPaginationModel, setFilterModel } =
        useCompanyImportReportMainContentController();
    return (
        <BackgroundStyleBox>
            <StyledTitle>
                <TitleContainer>Import Report</TitleContainer>
                <DownloadReportContainer>
                    <Button
                        type={ButtonTypes.TEXT}
                        onClick={handleDownloadImportReportButtonClick}
                        icon={<Icon icon={SyncDataIcons.DOWNLOAD} />}
                        alignment={'row' as Alignments}
                    >
                        <Typography>Download import Report</Typography>
                    </Button>
                </DownloadReportContainer>
            </StyledTitle>
            <StyledBox>
                <StyledBoxContainer>
                    <StyledDataGrid
                        rows={importReportRows}
                        columns={columns}
                        columnBuffer={8}
                        disableRowSelectionOnClick
                        pageSizeOptions={[10, 25, 50, 100]}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        filterMode="server"
                        onFilterModelChange={(model) => setFilterModel(model)}
                    />
                </StyledBoxContainer>
            </StyledBox>
        </BackgroundStyleBox>
    );
};

export default CompanyImportReportMainContent;
