import React, { useState } from 'react';
import { Icon, IconNames, MainMenuIcons } from 'design-system';

export type MenuItemData = {
    title: string;
    path: string;
    icon: JSX.Element;
};

const MENU_ITEMS: MenuItemData[] = [
    {
        title: 'Companies',
        path: '/companies',
        icon: <Icon icon={IconNames.COMPANIES} width={24} height={24} alt="" />,
    },
    {
        title: 'API Docs',
        path: '/api-docs',
        icon: <Icon icon={MainMenuIcons.API_DOCS} width={24} height={24} alt="" />,
    },
];

const useSelectedItem = () => {
    const [selectedItem, setSelectedItem] = useState<MenuItemData | undefined>(undefined);
    return { selectedItem, setSelectedItem };
};

export { useSelectedItem, MENU_ITEMS };
