import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import styled from 'styled-components';
import Logo from './drupe_logo.svg';
import LogoCondensed from './drupe_logo_condensed.svg';
import MenuItem from './MenuItem';
import { MENU_ITEMS, useSelectedItem } from './useSelectedMenu';

const openedMixin = (theme: any) => `
    width: ${theme.palette.Measures.width.menu};
    transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    })};
    overflow-x: hidden;
    background-color: ${theme.palette.Background.button.mainMenu.enabled};
    color: white;
`;

const closedMixin = (theme: any) =>
    `
    transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })};
    overflow-x: hidden;
    width: calc(${theme.spacing(7)} + 1px);
    ${theme.breakpoints.up('sm')}: {
        width: calc(${theme.spacing(8)} + 1px),
    };
    background-color: ${theme.palette.Background.button.mainMenu.enabled};
    color: white;
`;

const StyledDrawer = styled(MuiDrawer)`
    width: ${({ theme }) => theme.palette.Measures.width.menu};
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;

    ${({ theme, open }) => open && openedMixin(theme)};
    ${({ theme, open }) => open && `& .MuiDrawer-paper {${openedMixin(theme)}}`};
    ${({ theme, open }) => !open && closedMixin(theme)};
    ${({ theme, open }) => !open && `& .MuiDrawer-paper {${closedMixin(theme)}}`};
`;

const StyledDrawerHeader = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65px;
`;

const StyledDrawerArrowIcon = styled('div')`
    padding: 0;
    margin-left: auto;
    color: white;
`;

const StyledList = styled(List)`
    display: flex;
    margin-top: 10px !important;
    flex-direction: column;
    align-items: center;
`;

interface MainMenuProps {
    isMenuOpen: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

const MainMenu = ({ isMenuOpen, handleDrawerOpen, handleDrawerClose }: MainMenuProps) => {
    const { selectedItem, setSelectedItem } = useSelectedItem();

    return (
        <StyledDrawer variant="permanent" open={isMenuOpen}>
            <StyledDrawerHeader>
                {isMenuOpen && (
                    <>
                        <StyledDrawerArrowIcon onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </StyledDrawerArrowIcon>
                        <Box component="img" alt="Drupe logo." src={Logo} />
                    </>
                )}
                {!isMenuOpen && (
                    <>
                        <StyledDrawerArrowIcon onClick={handleDrawerOpen}>
                            <ChevronRightIcon />
                        </StyledDrawerArrowIcon>
                        <Box component="img" alt="Drupe logo." src={LogoCondensed} />
                    </>
                )}
            </StyledDrawerHeader>
            <StyledList>
                {MENU_ITEMS.map((item) => (
                    <MenuItem
                        isMenuOpen={isMenuOpen}
                        title={item.title}
                        path={item.path}
                        icon={item.icon}
                        isSelected={selectedItem === item}
                        onClick={() => setSelectedItem(item)}
                        key={item.title}
                    />
                ))}
            </StyledList>
        </StyledDrawer>
    );
};

export default MainMenu;
