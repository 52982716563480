import ModalSelector from '../../../common/components/ModalSelector';
import { useCrmObjectTypes } from './hooks/useCrmObjectTypes';
import { useSelectedObjectTypes } from './hooks/useSelectedObjectTypes';
import { mapObjectTypesToModalSelectorObjects } from './mappers/mapObjectTypesToModalSelectorObjects';
import { useCompanyDefinitionRepository } from '../../../common/hooks/useCompanyDefinitionRepository';
import { createOrUpdateCompanyDefinition } from './mappers/createOrUpdateCompanyDefinition';
import { ObjectType } from '../../../domain/Crm/ObjectType';

export const ObjectDefinitionsSelectorModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    const { isLoading, objectTypes } = useCrmObjectTypes();
    const { selectedObjectTypeIds, toggleObjectType } = useSelectedObjectTypes();
    const { editCompanyDefinition, companyDefinition } = useCompanyDefinitionRepository();

    const modalSelectorObjectTypes = mapObjectTypesToModalSelectorObjects(objectTypes, companyDefinition);

    const getSelectedObjectsByIndexes = (): ObjectType[] => {
        return selectedObjectTypeIds.map((index) => objectTypes[Number(index)]);
    };

    return (
        <ModalSelector
            visible={isOpen}
            onConfirm={() => {
                editCompanyDefinition(
                    createOrUpdateCompanyDefinition(getSelectedObjectsByIndexes(), companyDefinition)
                );
                close();
            }}
            onClose={close}
            objects={modalSelectorObjectTypes}
            selectedObjectIds={selectedObjectTypeIds}
            loading={isLoading}
            handleOnObjectClick={toggleObjectType}
            modalTitle={'Retrieve objects from CRM'}
            modalSubtitle={'Select objects'}
            objectDescription={'CRM API Name / CRM Label'}
            uncheckedLabel={'Select'}
            checkedLabel={'Selected to be retrieved'}
            primaryButtonCaption={'Confirm'}
            secondaryButtonCaption={'Back'}
            secondaryButtonAction={close}
        />
    );
};
