const useLocalStorage = () => {
    const localStorage = window.localStorage;

    const getFromStorage = (key: string) => {
        return JSON.parse(localStorage.getItem(key) || '{}');
    };

    const setInStorage = (key: string, value: any) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const removeFromStorage = (key: string) => {
        localStorage.removeItem(key);
    };

    return { getFromStorage, setInStorage, removeFromStorage };
};

export default useLocalStorage;
