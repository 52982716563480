export const dealsHS = [
    {
        name: 'external_record_url',
        label: 'Record URL (View Only)',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'dealname',
        label: 'Deal Name',
        isMandatory: true,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'dealstage',
        label: 'Deal Stage',
        isMandatory: true,
        isSearchable: false,
        isFilterable: true,
        isColorizable: true,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
        ],
    },
    {
        name: 'amount',
        label: 'Amount',
        isMandatory: false,
        isSearchable: true,
        isFilterable: true,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'numeric',
        decimals: 0,
    },
];
