import { useState } from 'react';

export const useSelectedObjectTypes = () => {
    const [selectedObjectTypeIds, setSelectedObjectTypeIds] = useState<string[]>([]);

    const toggleObjectType = (id: string) => {
        setSelectedObjectTypeIds((prev) => {
            if (prev.includes(id)) {
                return prev.filter((prevId) => prevId !== id);
            }
            return [...prev, id];
        });
    };

    return { selectedObjectTypeIds, toggleObjectType };
};
