import { useMultiUserSelectorControllerType } from './hooks/useMultiUserSelectorController';
import ModalSelector, { ModalSelectorObject } from '../../common/components/ModalSelector';

export interface MultiUserSelectorProps {
    visible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    useController: useMultiUserSelectorControllerType;
    selectLabel: string;
    selectedLabel: string;
    description: string;
}

const MultiUserSelector = ({
    visible,
    onConfirm,
    onClose,
    useController,
    selectLabel,
    selectedLabel,
    description,
}: MultiUserSelectorProps) => {
    const { users, selectedCrmUsersIdsInSelector, loading, handleOnUserClick, handleOnConfirm } =
        useController(onConfirm);

    const objects = users.map(
        (user) =>
            ({
                id: user.crmUserId,
                primaryText: `${user.firstName} ${user.lastName}`,
                secondaryText: user.email,
                isExcluded: user.isIntegrated,
            }) as ModalSelectorObject
    );

    return (
        <ModalSelector
            visible={visible}
            onConfirm={handleOnConfirm}
            onClose={onClose}
            objects={objects}
            selectedObjectIds={selectedCrmUsersIdsInSelector}
            loading={loading}
            handleOnObjectClick={handleOnUserClick}
            modalTitle="List of CRM users"
            modalSubtitle="Select users"
            objectDescription={description}
            uncheckedLabel={selectLabel}
            checkedLabel={selectedLabel}
            primaryButtonCaption={'Confirm'}
        />
    );
};

export default MultiUserSelector;
