import { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedCompanyId } from '../../../store/slices/companiesSlice';
import { useNavigate } from 'react-router-dom';
import { useCompanyUsersRepository } from '../../../common/hooks/useCompanyUsersRepository';
import { useSelectDraftUsers } from '../../../common/hooks/useSelectDraftUsers';

const useCompanyUsersMainContentController = () => {
    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const navigate = useNavigate();
    const companyId = useAppSelector(selectSelectedCompanyId);
    const { users } = useCompanyUsersRepository();
    const { draftUsers, selectDraftUsersToUpdate } = useSelectDraftUsers();

    const onConfirm = () => {
        setShowAddUsersModal(false);
        navigate(`/companies/${companyId}/users/add-users`);
    };

    const handleOnCancel = (userIdToDelete: string) => {
        selectDraftUsersToUpdate(draftUsers.filter((user) => user.crmUserId !== userIdToDelete));
    };

    return { showAddUsersModal, setShowAddUsersModal, onConfirm, users, handleOnCancel };
};

export { useCompanyUsersMainContentController };

export type useCompanyUsersMainContentControllerType = typeof useCompanyUsersMainContentController;
