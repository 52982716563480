import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
    companyObjectTypesRetrievalRequested,
    selectCompanyCrmObjectTypes,
    selectIsLoadingCompanyCrmObjectTypes,
} from '../../../../store/slices/companyCrmObjectTypesSlice';
import { useEffect } from 'react';
import { selectSelectedCompanyId } from '../../../../store/slices/companiesSlice';

export const useCrmObjectTypes = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingCompanyCrmObjectTypes);
    const objectTypes = useAppSelector(selectCompanyCrmObjectTypes);
    const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

    useEffect(() => {
        if (!isLoading && selectedCompanyId) dispatch(companyObjectTypesRetrievalRequested());
    }, [selectedCompanyId]);

    return { isLoading, objectTypes };
};
