import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useColumnsVisibilityEditorController } from './ColumnsVisibilityEditorController';

const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    min-width: 250px;
    margin: 15px;
`;

const ColumnsWrapper = styled.label`
    display: flex;
    flex-direction: row;
    gap: 0px;
    width: 100%;
    height: 30px;
    justify-content: space-between;
`;

const ColumnLabel = styled.div`
    height: 30px;
    line-height: 30px;
`;

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
`;

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export interface ColumnsVisibilityEditorProps {
    columns: any[];
    handleVisibilityChange: (columnName: string, visible: boolean) => void;
}

const ColumnsVisibilityEditor = ({ columns, handleVisibilityChange }: ColumnsVisibilityEditorProps) => {
    const { filteredColumns, filterColumns, hideAll, hideColumn } = useColumnsVisibilityEditorController(
        columns,
        handleVisibilityChange
    );
    return (
        <EditorContainer>
            <SearchWrapper>
                <TextField
                    label="Find column"
                    placeholder="Column title"
                    variant="standard"
                    color="primary"
                    fullWidth
                    focused
                    onChange={(event) => filterColumns(event.target.value)}
                />
            </SearchWrapper>
            {filteredColumns.map((column: any) => {
                return (
                    <div key={column.name}>
                        <ColumnsWrapper>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={column.visible}
                                        onChange={(event: any) => hideColumn(column.name, !event.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        size="small"
                                        disabled={column.name === 'name' || column.name === 'label'}
                                    />
                                }
                                label={column.label}
                            />
                        </ColumnsWrapper>
                    </div>
                );
            })}
            <ActionsWrapper>
                <Button
                    variant="text"
                    onClick={() => {
                        hideAll(true);
                    }}
                >
                    HIDE ALL
                </Button>
                <Button
                    variant="text"
                    onClick={() => {
                        hideAll(false);
                    }}
                >
                    SHOW ALL
                </Button>
            </ActionsWrapper>
        </EditorContainer>
    );
};

export default ColumnsVisibilityEditor;
