import { useSelectCompany } from './useSelectCompany';
import CompanyDefinition from '../../domain/CompanyDefinition/CompanyDefinition';
import { useGetCompanyDefinitionQuery, useUpdateCompanyDefinitionMutation } from '../../store/api/badgerApi';
import { skipToken } from '@reduxjs/toolkit/query';

const useCompanyDefinitionRepository = () => {
    const { selectedCompanyId } = useSelectCompany();

    const {
        data: companyDefinition,
        isError: isApiError,
        error,
    } = useGetCompanyDefinitionQuery(selectedCompanyId ?? skipToken);
    const isError = isApiError && 'status' in error && error.status !== 404;

    const [updateCompanyDefinition, info] = useUpdateCompanyDefinitionMutation();
    const doEditCompanyDefinition = (newCompanyDefinition: CompanyDefinition) => {
        if (selectedCompanyId) {
            updateCompanyDefinition({ companyId: selectedCompanyId, companyDefinition: newCompanyDefinition });
        }
    };

    return { companyDefinition, isError, editCompanyDefinition: doEditCompanyDefinition, isSaving: info.isLoading };
};

export { useCompanyDefinitionRepository };
