import { MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Control, RegisterOptions } from 'react-hook-form/dist/types';
import styled from 'styled-components';
import { Option } from '../../defs';

const StyledSelect = styled(Select)`
    width: 100%;
    height: 40px;
    margin: 8px 0;
    border-radius: 0 !important;

    &.MuiOutlinedInput-root {
        & fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        &:hover fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        & .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        &:hover .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        &.Mui-focused .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }
    }
`;

interface Props {
    disabled?: boolean;
    rules: RegisterOptions;
    control: Control;
    name: string;
    options: Option[];
    handleChange?: (value: string) => void;
}

const PicklistTypeField = ({ disabled, rules, control, name, options, handleChange }: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
                return field.value !== undefined ? (
                    <StyledSelect
                        data-testid={`${name}-select`}
                        value={field.value}
                        label=""
                        disabled={disabled}
                        onChange={(data) => {
                            field.onChange(data);
                            if (handleChange) {
                                handleChange(data.target.value as string);
                            }
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.uuid} value={option.uuid}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                ) : (
                    <></>
                );
            }}
        />
    );
};

export default PicklistTypeField;
