import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendHost } from '../../common/configuration/ConfigProvider';
import { RootState } from '../store';
import { mapFromAPI, mapToAPI } from './companyDefinition/companyDefinitionMapper';
import CompanyDefinition from '../../domain/CompanyDefinition/CompanyDefinition';

const baseQuery = fetchBaseQuery({
    baseUrl: backendHost,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).userAuth.token;
        if (token) {
            headers.set('Authorization', token);
        }
        return headers;
    },
});

export const badgerApi = createApi({
    reducerPath: 'badgerApi',
    baseQuery,
    tagTypes: ['CompanyDefinition'],
    endpoints: (builder) => ({
        getCompanyDefinition: builder.query<CompanyDefinition, string>({
            query: (companyId) => `/api/3/records/companies/${companyId}/company-definition`,
            transformResponse: (response) => mapFromAPI(response),
            providesTags: (result, error, companyId) => [{ type: 'CompanyDefinition', companyId }],
        }),
        updateCompanyDefinition: builder.mutation<
            CompanyDefinition,
            { companyId: string; companyDefinition: CompanyDefinition }
        >({
            query: (data) => ({
                url: `/api/3/records/companies/${data.companyId}/company-definition`,
                method: 'PUT',
                body: mapToAPI(data.companyDefinition),
            }),
            transformResponse: (response) => mapFromAPI(response),
            invalidatesTags: (result, error, { companyId }) => [{ type: 'CompanyDefinition', companyId }],
        }),
    }),
});

export const { useGetCompanyDefinitionQuery, useUpdateCompanyDefinitionMutation } = badgerApi;
