import CompanyDefinition, {
    BadgerObjectDefinition,
    DisplayType,
    FieldType,
    SortingOrderDirectionType,
    TextField,
} from '../../../../domain/CompanyDefinition/CompanyDefinition';

export type NewObjectDefinition = {
    name: string;
    label: string;
};

export const createOrUpdateCompanyDefinition = (
    newObjectDefinitions: NewObjectDefinition[],
    originalCompanyDefinition?: CompanyDefinition
) => {
    let order = originalCompanyDefinition?.badgerObjectDefinitions.length
        ? findHighestOrderValue(originalCompanyDefinition.badgerObjectDefinitions) + 1
        : 0;

    const newBadgerObjectDefinitions = newObjectDefinitions.map((newDefinition, index) => {
        return {
            name: newDefinition.name,
            namePlural: `${newDefinition.name}`,
            label: newDefinition.label,
            labelPlural: `${newDefinition.label}s`,
            isRoot: true,
            order: order + index,
            fields: [
                {
                    type: FieldType.TEXT,
                    isTextArea: false,
                    maxLength: 50,
                    name: 'example_field',
                    label: 'Example Field - change me',
                    isMandatory: false,
                    isSearchable: false,
                    isFilterable: false,
                    isColorizable: false,
                    isViewOnly: false,
                    displayOrder: 0,
                    sortingOrder: 0,
                    sortingOrderDirection: SortingOrderDirectionType.ASCENDING,
                    displayType: DisplayType.AS_DETAILS,
                } as TextField,
            ],
        };
    });

    return {
        badgerObjectDefinitions: [
            ...(originalCompanyDefinition?.badgerObjectDefinitions || []),
            ...newBadgerObjectDefinitions,
        ],
        linkDefinitions: [],
    } as CompanyDefinition;
};

const findHighestOrderValue = (array: BadgerObjectDefinition[]): number => {
    return array.reduce((max, obj) => (obj['order'] > max ? obj['order'] : max), -Infinity);
};
