import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';
import useUserAuth from '../../../common/hooks/useUserAuth';
import { ImportReportError } from '../../../domain/Crm/ImportReportError';

const IMPORT_REPORT_ENDPOINT_URL = '/api/3/imports/errors';

type ImportReportResponse = {
    results: {
        external_record_id: string;
        external_record_url: string;
        badger_object: {
            object_type: string;
            label: string;
        };
        errors: {
            field_name: string;
            error_name: string;
            description: string;
            recommendations: string[];
            severity: 'WARNING' | 'ERROR';
        }[];
    }[];
};

export const ImportReportApiConnector = () => {
    const { fetch } = HttpClient();
    const { userAuth } = useUserAuth();

    const generateId = () => {
        const timestamp = Date.now();
        return timestamp + Math.random().toString(36).substring(2, 15);
    };

    const mapToDomain = (response: ImportReportResponse): ImportReportError[] => {
        return response.results.map((result) => ({
            externalRecordId: result.external_record_id,
            externalRecordUrl: result.external_record_url,
            badgerObject: {
                objectType: result.badger_object.object_type,
                label: result.badger_object.label,
            },
            errors: result.errors.map((error) => ({
                id: generateId(),
                fieldName: error.field_name,
                errorName: error.error_name,
                description: error.description,
                recommendations: error.recommendations,
                severity: error.severity,
            })),
        }));
    };

    const getImportReport = async (
        companyId: string,
        page: number,
        pageSize: number,
        objectTypes: string,
        severity: string
    ): Promise<ImportReportError[] | null> => {
        try {
            const queryParams: string[] = [];
            queryParams.push(`page=${page}`);
            queryParams.push(`page_size=${pageSize}`);
            if (companyId !== '') queryParams.push(`company_id=${companyId}`);
            if (objectTypes !== '') queryParams.push(`object_types=${objectTypes}`);
            if (severity !== '') queryParams.push(`severity=${severity}`);
            const queryParamsURL = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

            const response = await fetch(
                HttpMethod.GET,
                `${IMPORT_REPORT_ENDPOINT_URL}${queryParamsURL}`,
                userAuth.token,
                undefined,
                {
                    Accept: 'application/json',
                }
            );
            return mapToDomain(response.body);
        } catch (error) {
            return null;
        }
    };

    const downloadImportReport = async (companyId: string) => {
        try {
            const response = await fetch(
                HttpMethod.GET,
                `${IMPORT_REPORT_ENDPOINT_URL}?company_id=${companyId}`,
                userAuth.token,
                undefined,
                {
                    Accept: 'text/csv',
                }
            );
            return (response as Response).blob();
        } catch (error) {
            return null;
        }
    };

    return {
        getImportReport,
        downloadImportReport,
    };
};
