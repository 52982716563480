export const leadsSF = [
    {
        name: 'external_record_url',
        label: 'Record URL (View Only)',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'first_name',
        label: 'First Name',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'last_name',
        label: 'Last Name',
        isMandatory: true,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Email',
        label: 'Email',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_SUBTITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Company',
        label: 'Company',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Phone',
        label: 'Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'MobilePhone',
        label: 'Mobile Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'address',
        label: 'Address',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'address',
        accuracyTypes: ['ROOFTOP', 'RANGE_INTERPOLATED'],
    },
];
