export const contactsSF = [
    {
        name: 'external_record_url',
        label: 'Record URL (View Only)',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'first_name',
        label: 'First Name',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'last_name',
        label: 'Last Name',
        isMandatory: true,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'email',
        label: 'Email',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_SUBTITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'phone',
        label: 'Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'mobilephone',
        label: 'Mobile Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'mailingaddress',
        label: 'Mailing Address',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'address',
        accuracyTypes: ['ROOFTOP', 'RANGE_INTERPOLATED'],
    },
];
