import { useState, useEffect } from 'react';
import { buildColumns, CONDITIONAL_COLUMNS } from '../columns';
import useLocalStorage from '../../../../common/hooks/useLocalStorage';
import { Column } from '../../defs';

const useColumnsVisibility = () => {
    const [columns, setColumns] = useState<Column[]>(buildColumns());
    const [showConditionalColumns, setShowConditionalColumns] = useState<boolean>(false);

    const { getFromStorage, setInStorage } = useLocalStorage();

    useEffect(() => {
        updateVisibleColumns(showConditionalColumns);
    }, [showConditionalColumns]);

    useEffect(() => {
        const columnsVisibilityPreferences = getFromStorage('columnsVisibilityPreferences');
        if (columnsVisibilityPreferences) {
            const updatedColumns = [...columns];
            updatedColumns.forEach((column) => {
                if (columnsVisibilityPreferences[column.name] !== undefined) {
                    column.visible = columnsVisibilityPreferences[column.name];
                }
            });
            setColumns(updatedColumns);
        }
    }, []);

    const updateVisibleColumns = (getConditionalColumns: boolean) => {
        const conditionalColumns = columns.filter((column) => CONDITIONAL_COLUMNS.includes(column.name));
        const columnsVisibilityPreferences = getFromStorage('columnsVisibilityPreferences');
        conditionalColumns.forEach((column) => {
            const columnVisibilityPreference =
                columnsVisibilityPreferences[column.name] === undefined
                    ? true
                    : columnsVisibilityPreferences[column.name];
            column.visible = getConditionalColumns && columnVisibilityPreference;
        });
        const updatedColumns = [...columns];
        setColumns(updatedColumns);
        return updatedColumns;
    };

    const setColumnVisibility = (columnName: string, visibility: boolean) => {
        const updatedColumns = [...columns];
        updatedColumns.forEach((column) => {
            const columnsVisibilityPreferences = getFromStorage('columnsVisibilityPreferences');
            if (
                column.name === columnName ||
                (columnName === 'isMandatory' && column.name === 'isViewOnly') ||
                (columnName === 'isViewOnly' && column.name === 'isMandatory')
            ) {
                column.visible = visibility;
                setInStorage('columnsVisibilityPreferences', {
                    ...columnsVisibilityPreferences,
                    [column.name]: visibility,
                });
            }
        });
        setColumns(updatedColumns);
    };

    const setConditionalColumnsVisibility = (visibility: boolean) => {
        setShowConditionalColumns(visibility);
    };

    return { columns, updateVisibleColumns, setColumnVisibility, setConditionalColumnsVisibility };
};

export default useColumnsVisibility;
