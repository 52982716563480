import { BadgerObjectDefinition } from '../../../../domain/CompanyDefinition/CompanyDefinition';
import { contactsHS } from './HS-contacts';
import { engagementsHS } from './HS-engagements';
import { accountsSF } from './SF-accounts';
import { contactsSF } from './SF-contacts';
import { leadsSF } from './SF-leads';
import { tasksSF } from './SF-tasks';
import { opportunitiesSF } from './SF-opportunities';
import { companiesHS } from './HS-companies';
import { dealsHS } from './HS-deals';

export const addDefaultObjectMapping = (
    updatedObjectDefinition: BadgerObjectDefinition | undefined,
    option: string
) => {
    if (updatedObjectDefinition) {
        switch (option) {
            case 'Hubspot-Contacts':
                contactsHS.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Hubspot-Engagements':
                engagementsHS.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Hubspot-Companies':
                companiesHS.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Hubspot-Deals':
                dealsHS.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Salesforce-Accounts':
                accountsSF.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Salesforce-Contacts':
                contactsSF.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Salesforce-Leads':
                leadsSF.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Salesforce-Opportunities':
                opportunitiesSF.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            case 'Salesforce-Tasks':
                tasksSF.forEach((field) => {
                    // @ts-ignore
                    updatedObjectDefinition.fields.push(field);
                });
                break;
            default:
                break;
        }
    }
};
