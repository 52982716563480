type Configuration = {
    backendHost: string | undefined;
};

const getConfiguration = (): Configuration => {
    const backendHost = process.env.REACT_APP_BACKEND_HOST || '';
    return { backendHost };
};
const configuration = getConfiguration();
const { backendHost } = configuration;

export { configuration, backendHost, getConfiguration };
