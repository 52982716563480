import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Backdrop, Container, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button, { BUTTON_LEVELS, ButtonVariants } from '../common/components/DS/Button';
import { useCompanyUsersMainContentController } from '../CompanyPage/CompanyUsersPage/hooks/useCompanyUsersMainContentController';
import { loadFontAttributes } from 'design-system';
import { useAddUsersToBadgerMainContentController } from './hooks/useAddUsersToBadgerMainContentController';
import { DraftUser } from '../domain/Users/DraftUser';
import CircularProgress from '@mui/material/CircularProgress';
import ModalSelector from '../common/components/ModalSelector';

const StyledContainer = styled(Container)`
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const StyledTableContainer = styled(TableContainer)`
    padding: 30px 25px !important;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const StyledTableHead = styled(TableHead)`
    height: 36px !important;
    background-color: ${({ theme }) => theme.palette.Background.table.header.default};
    box-shadow: 0px 1px 1px 0px #acacac3f;
`;

const StyledTableRow = styled(TableRow)`
    background-color: ${({ theme }) => theme.palette.Background.table.content.default} !important;
    border-bottom: 2px solid ${({ theme }) => theme.palette.Basic.lighter};
`;

const StyledHeaderTableCell = styled(TableCell)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)};
    line-height: ${({ theme }) => theme.typography.Small.bold.lineHeight} !important;
`;

const StyledTableCell = styled(TableCell)`
    padding: 8px 4px 0px 4px !important;
`;

const StyledTableBody = styled(TableBody)`
    background-color: ${({ theme }) => theme.palette.Background.table.content.default} !important;
`;

const StyledBodyContainer = styled.div`
    display: flex !important;
    justify-content: left;
    align-items: center;
    height: 59px !important;
    padding: 10px;
`;

const StyledAddCircleIcon = styled(AddCircleIcon)`
    width: 24px !important;
    height: 24px !important;
    color: ${({ theme }) => theme.palette.Background.button.primary.enabled} !important;

    &:hover {
        cursor: pointer;
    }
`;

const StyledFooterContainer = styled.div`
    height: 66px;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.palette.Background.table.content.default} !important;
    display: flex !important;
    justify-content: flex-end !important;
`;

const StyledTextField = styled(TextField)<any>`
    height: 100% !important;

    & .MuiOutlinedInput-root {
        border-radius: 0 !important;
        padding: 10px;
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.filled} !important;
    }

    & .MuiOutlinedInput-root.Mui-error {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.error} !important;
    }

    & .MuiOutlinedInput-root fieldset {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.filled} !important;
    }

    & .MuiOutlinedInput-root.Mui-error fieldset {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.error} !important;
    }

    & .MuiOutlinedInput-root.Mui-error:hover {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.focus} !important;
    }

    & .MuiOutlinedInput-root:hover fieldset {
        border-radius: 0 !important;
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.focus} !important;
    }

    & .MuiOutlinedInput-root.Mui-error:hover fieldset {
        border-radius: 0 !important;
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.focus} !important;
    }

    & .MuiOutlinedInput-input {
        padding: 0px;
        ${({ theme }) => loadFontAttributes(theme.typography.Large.regular)};
    }

    & .MuiInputBase-root.Mui-focused {
        background-color: ${({ theme }) => theme.palette.Background.fields.textField.focus} !important;
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.focus} !important;
    }

    & .MuiInputBase-root.Mui-focused fieldset {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.focus} !important;
    }

    & .MuiInputBase-root.Mui-error.Mui-focused {
        border: 1px solid ${({ theme }) => theme.palette.Border.fields.textField.focus} !important;
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    color: ${({ theme }) => theme.palette.Background.severity.info} !important;
    width: 14px !important;
    height: 14px !important;

    &:hover {
        cursor: pointer;
    }
`;

const StyledTooltip = styled(Tooltip)`
    & .MuiTooltip-tooltip {
        border-bottom: 4px;
        align-items: center;
        background-color: ${({ theme }) => theme.palette.Background.tooltip.default} !important;
        display: flex;
        width: 128px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        padding: 10px;
    }

    & .MuiTooltip-arrow {
        color: ${({ theme }) => theme.palette.Background.tooltip.default} !important;
    }
`;

const StyledCancelIcon = styled(CancelIcon)`
    color: ${({ theme }) => theme.palette.Text.onSurface.medium} !important;
    width: 16px !important;
    height: 16px !important;
    float: right;
    margin: 8px 4px 8px 0 !important;

    &:hover {
        cursor: pointer;
    }
`;

const StyledErrorMessageContainer = styled.div`
    color: ${({ theme }) => theme.palette.Text.severity.alert} !important;
    ${({ theme }) => loadFontAttributes(theme.typography.X_Small.regular)};
    height: 14px;
    padding-left: 10px;
`;

const StyledBackdrop = styled(Backdrop)`
    z-index: ${({ theme }) => theme.zIndex.drawer + 1} !important;
    // TODO: change to theme color, waiting for #BMG-590
    background-color: #ffffff66 !important;
    pointer-events: auto !important;
`;

const AddUsersToBadgerMainContent = (props: { 'aria-label'?: string }) => {
    const { showAddUsersModal, setShowAddUsersModal, onConfirm, handleOnCancel } =
        useCompanyUsersMainContentController();
    const {
        loading,
        handleOnConfirm,
        draftUsers,
        handleFullNameChange,
        handleEmailChange,
        handleUsernameChange,
        usernameValidationErrors,
        modalSelectorObjects,
    } = useAddUsersToBadgerMainContentController();

    const renderAddUsersModal = () => (
        <ModalSelector
            modalTitle="List of CRM users"
            modalSubtitle="Select users"
            visible={showAddUsersModal}
            onConfirm={onConfirm}
            onClose={() => setShowAddUsersModal(false)}
            objects={modalSelectorObjects}
            selectedObjectIds={draftUsers.map((user) => user.crmUserId)}
            loading={loading}
            handleOnObjectClick={(id) => console.log(`Object clicked: ${id}`)}
            uncheckedLabel="Select"
            checkedLabel="Selected to be retrieved"
            primaryButtonCaption={'Confirm'}
            objectDescription="User full name/ Email"
        />
    );

    const renderLoadingOverlay = () => (
        <StyledBackdrop open={loading} aria-label="loading-overlay">
            <CircularProgress />
        </StyledBackdrop>
    );

    const isThereAnErrorOnEmail = (draftUser: DraftUser) =>
        draftUser.creationResult && draftUser.creationResult.errors && draftUser.creationResult.errors.email;
    const isThereAnErrorOnUsername = (draftUser: DraftUser) => {
        const draftUserUsernameErrors = draftUser?.creationResult?.errors?.username;
        return draftUserUsernameErrors || usernameValidationErrors[draftUser.crmUserId];
    };

    // @ts-ignore
    return (
        <>
            {showAddUsersModal && renderAddUsersModal()}
            {loading && renderLoadingOverlay()}
            <StyledContainer {...props} maxWidth={false}>
                <StyledTableContainer>
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <StyledHeaderTableCell>Full name</StyledHeaderTableCell>
                                <StyledHeaderTableCell>Email</StyledHeaderTableCell>
                                <StyledHeaderTableCell>Username</StyledHeaderTableCell>
                                <StyledHeaderTableCell style={{ width: '35%' }} />
                            </TableRow>
                        </StyledTableHead>
                        <StyledTableBody>
                            {draftUsers.map((draftUser) => (
                                <StyledTableRow key={draftUser.crmUserId}>
                                    <StyledTableCell>
                                        <StyledTextField
                                            defaultValue={`${draftUser.firstName}${
                                                draftUser.lastName ? ` ${draftUser.lastName}` : ''
                                            }`}
                                            onChange={(e: { target: { value: string } }) => {
                                                handleFullNameChange(e.target.value, draftUser.crmUserId);
                                            }}
                                            fullWidth
                                        />
                                        <StyledErrorMessageContainer />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <StyledTextField
                                            defaultValue={draftUser.email}
                                            fullWidth
                                            onChange={(e: { target: { value: string } }) => {
                                                handleEmailChange(e.target.value, draftUser.crmUserId);
                                            }}
                                            error={isThereAnErrorOnEmail(draftUser)}
                                        />
                                        <StyledErrorMessageContainer
                                            aria-label={`Email error message - ${draftUser.crmUserId}`}
                                        >
                                            {isThereAnErrorOnEmail(draftUser)}
                                        </StyledErrorMessageContainer>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <StyledTextField
                                            defaultValue={draftUser.username}
                                            fullWidth
                                            onChange={(e: { target: { value: string } }) => {
                                                handleUsernameChange(e.target.value, draftUser.crmUserId);
                                            }}
                                            onBlur={(e: { target: { value: string } }) => {
                                                handleUsernameChange(e.target.value, draftUser.crmUserId);
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        aria-label={`info-icon-${draftUser.crmUserId}`}
                                                    >
                                                        <StyledTooltip
                                                            title="The username has been auto populated with mail prefix. The username can be changed manually"
                                                            placement="right"
                                                            arrow
                                                        >
                                                            <StyledInfoIcon />
                                                        </StyledTooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={isThereAnErrorOnUsername(draftUser)}
                                        />
                                        <StyledErrorMessageContainer
                                            aria-label={`Username error message - ${draftUser.crmUserId}`}
                                        >
                                            {isThereAnErrorOnUsername(draftUser)}
                                        </StyledErrorMessageContainer>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <StyledCancelIcon
                                            aria-label={`cancel-icon-${draftUser.crmUserId}`}
                                            onClick={() => handleOnCancel(draftUser.crmUserId)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </StyledTableBody>
                    </Table>
                    <StyledBodyContainer>
                        <StyledAddCircleIcon aria-label="add-users" onClick={() => setShowAddUsersModal(true)} />
                    </StyledBodyContainer>
                </StyledTableContainer>
                <StyledFooterContainer>
                    <Button
                        variant={ButtonVariants.CONTAINED}
                        severity={BUTTON_LEVELS.INFO}
                        disabled={
                            loading ||
                            draftUsers.length === 0 ||
                            draftUsers.some((user) => Boolean(usernameValidationErrors[user.crmUserId]))
                        }
                        onClick={handleOnConfirm}
                    >
                        {loading ? 'Confirming...' : 'Confirm'}
                    </Button>
                </StyledFooterContainer>
            </StyledContainer>
        </>
    );
};

export default AddUsersToBadgerMainContent;
