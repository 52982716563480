import { useSelectDraftUsers } from '../../common/hooks/useSelectDraftUsers';
import { useCreateCompanyCrmUsersRepository } from './useCreateCompanyCrmUsersRepository';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserCreationResult, UserCreationStatus } from '../../domain/Users/UserCreationResult';
import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { ModalSelectorObject } from '../../common/components/ModalSelector';

interface UsernameValidationErrors {
    [key: string]: string | null;
}

const useAddUsersToBadgerMainContentController = () => {
    const [loading, setLoading] = useState(false);
    const { draftUsers, selectDraftUsersToUpdate } = useSelectDraftUsers();
    const [usernameValidationErrors, setUsernameValidationErrors] = useState<UsernameValidationErrors>({});
    const { userCreationResults, dispatchCreateCompanyCrmUsers } = useCreateCompanyCrmUsersRepository();
    const navigate = useNavigate();

    const { selectedCompanyId } = useSelectCompany();

    useEffect(() => {
        setLoading(false);
        if (userCreationResults.length > 0) {
            const updatedDraftUsers = userCreationResults.map((creationResult, index) => ({
                ...draftUsers[index],
                creationResult: creationResult,
            }));
            selectDraftUsersToUpdate(updatedDraftUsers);
        }
    }, [userCreationResults]);

    useEffect(() => {
        validateDraftUserUsernames();
    }, [draftUsers]);

    const validateDraftUserUsernames = () => {
        const validationErrors: UsernameValidationErrors = {};
        draftUsers.forEach((user) => {
            validationErrors[user.crmUserId] = validateUsernamePrefix(user.username);
            if (!validationErrors[user.crmUserId]) {
                validationErrors[user.crmUserId] = validateUsernameDuplicates(user.username);
            }
        });
        setUsernameValidationErrors(validationErrors);
    };

    const validateUsernamePrefix = (newUsername: string): string | null => {
        if (!newUsername.startsWith('go.')) {
            return "Must have 'go.' prefix. Ex: go.johndoe";
        }
        return null;
    };

    const validateUsernameDuplicates = (newUsername: string): string | null => {
        const duplicatedUsernames = draftUsers.filter((user) => user.username === newUsername);
        if (duplicatedUsernames.length > 1) {
            return 'This Username is duplicated. Please enter a unique Username.';
        }
        return null;
    };

    const handleFullNameChange = (newFullName: string, crmUserId: string) => {
        const [firstName, ...lastNameParts] = newFullName.split(' ');
        const lastName = lastNameParts.join(' ');

        const updatedDraftUsers = draftUsers.map((user) => {
            if (user.crmUserId === crmUserId) {
                return {
                    ...user,
                    firstName,
                    lastName,
                };
            }
            return user;
        });
        selectDraftUsersToUpdate(updatedDraftUsers);
    };

    const handleEmailChange = (newEmail: string, crmUserId: string) => {
        const updatedDraftUsers = draftUsers.map((user) => {
            if (user.crmUserId === crmUserId) {
                return {
                    ...user,
                    email: newEmail,
                };
            }
            return user;
        });
        selectDraftUsersToUpdate(updatedDraftUsers);
    };

    const handleUsernameChange = (newUsername: string, crmUserId: string) => {
        const updatedDraftUsers = draftUsers.map((user) => {
            if (user.crmUserId === crmUserId) {
                return {
                    ...user,
                    username: newUsername,
                };
            }
            return user;
        });
        selectDraftUsersToUpdate(updatedDraftUsers);
    };

    const handleOnConfirm = async () => {
        setLoading(true);
        const result: UserCreationResult[] = await dispatchCreateCompanyCrmUsers();

        if (result) {
            const allSuccess = result.every((item) => item.status === UserCreationStatus.SUCCEEDED);
            if (allSuccess) {
                navigate(`/companies/${selectedCompanyId}/users`);
            }
        }
    };

    const modalSelectorObjects: ModalSelectorObject[] = draftUsers.map((user) => ({
        id: user.crmUserId,
        primaryText: `${user.firstName} ${user.lastName}`,
        secondaryText: user.email,
        isExcluded: false,
    }));

    return {
        loading,
        handleOnConfirm,
        draftUsers,
        handleFullNameChange,
        handleEmailChange,
        handleUsernameChange,
        userCreationResults,
        usernameValidationErrors,
        modalSelectorObjects,
    };
};

export { useAddUsersToBadgerMainContentController };
