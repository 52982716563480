import { Box, Chip, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Control, RegisterOptions } from 'react-hook-form/dist/types';
import styled from 'styled-components';
import { Option } from '../defs';

const StyledSelect = styled(Select)`
    width: 100%;
    height: fit-content;
    min-height: 40px;
    border-radius: 0 !important;
    margin: 8px 0;

    &.MuiOutlinedInput-root {
        & .MuiSelect-select {
            padding: 4px 14px;
        }

        &:hover fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        & fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        & .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        &.Mui-focused .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        &:hover .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }
    }
`;

const SelectedOption = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5px;
`;

const Description = styled.div`
    margin-left: 10px;
    color: ${({ theme }) => theme.palette.Text.onButton.severity.info_disabled};
`;

export interface props {
    disabled?: boolean;
    rules: RegisterOptions;
    control: Control;
    name: string;
    options: Option[];
    handleChange?: (value: string[]) => void;
}

const MultiPicklistTypeField = ({ disabled, rules, control, name, options, handleChange }: props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
                return field.value !== undefined ? (
                    <StyledSelect
                        data-testid={`${name}-select`}
                        multiple
                        value={field.value}
                        disabled={disabled}
                        renderValue={(selected: any) => (
                            <SelectedOption>
                                {selected.map((value: any) => (
                                    <Chip key={value} label={options.find((option) => option.uuid === value)?.label} />
                                ))}
                            </SelectedOption>
                        )}
                        onChange={(data) => {
                            field.onChange(data);
                            if (handleChange) {
                                handleChange(data.target.value as string[]);
                            }
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.uuid} value={option.uuid}>
                                {option.label}
                                {option.description && <Description>{option.description}</Description>}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                ) : (
                    <></>
                );
            }}
        />
    );
};

export default MultiPicklistTypeField;
