import { Outlet } from 'react-router-dom';
import TabsPanel from './TabsPanel/TabsPanel';
import { TabsPanelControllerType } from './TabsPanel/tabsPanelController';
import { CompanyDefinitionContentControllerType } from './companyDefinitionContentController';
import { styled } from 'styled-components';
import { NoObjectDefinitionsContent } from './NoObjectDefinitionsContent/NoObjectDefinitionsContent';
import { useOnOff } from '../../common/hooks/useOnOff';
import { ObjectDefinitionsSelectorModal } from './ObjectDefinitionsSelectorModal/ObjectDefinitionsSelectorModal';
import Typography from '@mui/material/Typography';
import { Dialog } from 'design-system';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CompanyDefinitionContent = ({
    useController,
    useTabsPanelController,
}: {
    useController: CompanyDefinitionContentControllerType;
    useTabsPanelController: TabsPanelControllerType;
}) => {
    const { tabs, isError } = useController();
    const modalDisplayControl = useOnOff();

    return (
        <>
            {tabs.length > 0 ? (
                <Container>
                    <TabsPanel tabs={tabs} useController={useTabsPanelController} />
                    <Outlet />
                </Container>
            ) : (
                <NoObjectDefinitionsContent openObjectTypeSelectionModal={modalDisplayControl.setOn} />
            )}
            <ObjectDefinitionsSelectorModal isOpen={modalDisplayControl.isOn} close={modalDisplayControl.setOff} />
            <Dialog // TODO change this dialog to a component proposed by the Design Team - this was done by an engineer
                // basically we need to warn about errors in fetching company definition, this is (probably) a temp solution
                title={'⚠️ Error fetching company definition'}
                content={
                    <Typography variant={'h6'} align={'center'}>
                        There was an error fetching company definition for the company. Please try again
                    </Typography>
                }
                primaryButtonCaption={'Reload'}
                onPrimaryButtonClick={() => window.location.reload()}
                secondaryButtonCaption={'Back to main page'}
                onSecondaryButtonClick={() => (window.location.href = '/')}
                onClose={() => window.location.reload()}
                open={isError}
            />
        </>
    );
};

export default CompanyDefinitionContent;
