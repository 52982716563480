import { Column, ColumnType } from '../defs';

export const CONDITIONAL_COLUMNS = ['isColorizable', 'isFilterable'];

export const buildColumns = (): Column[] => [
    {
        name: 'displayOrder',
        label: '',
        type: ColumnType.ORDER,
        width: 50,
        visible: true,
        editable: false,
        needsResync: false,
    },
    {
        name: 'label',
        label: 'Badger Label',
        type: ColumnType.TEXT,
        width: 200,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        name: 'name',
        label: 'Badger API Name',
        type: ColumnType.TEXT,
        width: 200,
        visible: true,
        editable: true,
        needsResync: true,
    },
    {
        name: 'type',
        label: 'Badger Field Type',
        type: ColumnType.PICKLIST,
        width: 200,
        visible: true,
        editable: false,
        needsResync: true,
    },
    {
        name: 'isMandatory',
        label: 'Is mandatory',
        type: ColumnType.BOOLEAN,
        width: 115,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        name: 'isViewOnly',
        label: 'Is view only',
        type: ColumnType.BOOLEAN,
        width: 115,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        name: 'isColorizable',
        label: 'Is colorizable',
        type: ColumnType.BOOLEAN,
        width: 150,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        name: 'isFilterable',
        label: 'Is filterable',
        type: ColumnType.BOOLEAN,
        width: 150,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        name: 'isTextArea',
        label: 'Is text area',
        type: ColumnType.BOOLEAN,
        width: 150,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        name: 'pickList',
        label: 'Picklist labels:values',
        type: ColumnType.PICKLIST_LABELS_VALUES,
        width: 200,
        visible: true,
        editable: true,
        needsResync: true,
    },
    {
        id: 'decimals',
        name: 'decimals',
        label: 'Decimals',
        type: ColumnType.NUMBER,
        width: 100,
        visible: true,
        editable: true,
        needsResync: false,
    },
    {
        id: 'action',
        name: 'action',
        label: '',
        type: ColumnType.ACTION,
        width: 50,
        visible: true,
        editable: false,
        needsResync: true,
    },
];
